<script lang="ts" setup>
import type { EditorialSocialWall } from '@integration-layer/ampliance/schemas/editorial-social-wall-schema.localized'
import type { EditorialSocialWallProps } from '@design-system/components/Editorial/EditorialSocialWall.props'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: EditorialSocialWall
}>()

const data = await useComponentResponse<EditorialSocialWall>(props)
</script>

<template>
  <EditorialSocialWall
    v-if="data"
    v-bind="data as unknown as EditorialSocialWallProps"
  />
</template>
